import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Next: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 7 11" className={className}>
    <path
      fill="currentColor"
      d="M.31 1.69L4.44 5.5.3 9.32a.93.93 0 000 1.4c.42.38 1.09.38 1.5 0l4.88-4.53c.41-.38.41-1 0-1.38L1.8.29a1.12 1.12 0 00-1.5 0 .95.95 0 000 1.4z"
    />
  </svg>
);

export default Next;
