import React, { FunctionComponent } from "react";
import { Link } from "../../utils/i18n";
import First from "../Icons/First";
import Previous from "../Icons/Previous";
import Next from "../Icons/Next";
import Last from "../Icons/Last";
import { paginationNumber, active } from "./index.module.css";

export type Props = {
  currentPage: number;
  pages: number;
  className?: string;
};

const baseButtonClassName = "p-4 mx-[7px]";

const getLink = (page: number): string => {
  if (page === 0 || page === 1) return "/publications#publications-reports";
  return `/publications/${page}#publications-reports`;
};

const Pagination: FunctionComponent<Props> = ({
  pages,
  currentPage,
  className,
}) => {
  if (!pages || pages === 1) return null;

  const canFirst = currentPage !== 1;
  const previous = currentPage - 1;
  const canNext = currentPage !== pages;
  const next = currentPage !== pages ? currentPage + 1 : currentPage;
  let showedPages: Array<number>;

  if (pages < 5) {
    showedPages = Array(pages)
      .fill(undefined)
      .map((_, i) => i + 1);
  } else {
    if (currentPage < 4) {
      showedPages = Array(4)
        .fill(undefined)
        .map((_, i) => i + 1);
    } else if (currentPage === pages) {
      showedPages = [pages - 3, pages - 2, pages - 1, pages];
    } else {
      showedPages = [
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
      ];
    }
  }

  return (
    <div className={`${className} flex items-center flex-wrap justify-center`}>
      <div className="flex items-center order-2 md:order-1">
        <Link to={getLink(0)} className={baseButtonClassName}>
          <First
            className={`${
              !canFirst ? "text-oslo-grey opacity-25" : "text-abbey"
            } h-[11px]`}
          />
        </Link>
        <Link to={getLink(previous)} className={baseButtonClassName}>
          <Previous
            className={`${
              !previous ? "text-oslo-grey opacity-25" : "text-abbey"
            } h-[11px]`}
          />
        </Link>
      </div>

      <div className="flex items-center justify-center mx-10 mb-8 md:mb-0 w-full md:w-auto order-1 md:order-2">
        {showedPages.map((page) => (
          <Link
            key={page}
            to={getLink(page)}
            className={`${paginationNumber} ${
              currentPage === page ? active : "font-medium"
            } flex items-center justify-center mx-0.5 w-[45px] h-[45px] text-roboto text-lg`}
          >
            {page}
          </Link>
        ))}
      </div>

      <div className="flex items-center order-3">
        <Link to={getLink(next)} className={baseButtonClassName}>
          <Next
            className={`${
              !canNext ? "text-oslo-grey opacity-25" : "text-abbey"
            } h-[11px]`}
          />
        </Link>
        <Link to={getLink(pages)} className={baseButtonClassName}>
          <Last
            className={`${
              !canNext ? "text-oslo-grey opacity-25" : "text-abbey"
            } h-[11px]`}
          />
        </Link>
      </div>
    </div>
  );
};

export default Pagination;
