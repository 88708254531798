import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const First: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 11 11" className={className}>
    <path
      fill="currentColor"
      d="M2 1.1C2 .5 1.55 0 1 0S0 .5 0 1.1v8.8c0 .6.45 1.1 1 1.1s1-.5 1-1.1V1.1zm4.56 4.4L10.7 9.3a.95.95 0 010 1.4c-.42.39-1.09.39-1.5 0L4.31 6.2a.93.93 0 010-1.38L9.2.29a1.12 1.12 0 011.5 0c.41.38.41 1 0 1.39L6.56 5.5z"
    />
  </svg>
);

export default First;
