import React, { FunctionComponent } from "react";
import { Link, useI18next } from "../../utils/i18n";
import Clips from "./components/Clips";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

export type Props = {
  title: string;
  picture: any;
  date: string;
  excerpt: JSX.Element;
  link: string;
};

const PublicationsLastReport: FunctionComponent<Props> = ({
  picture,
  title,
  date,
  excerpt,
  link,
}) => {
  const { language, t } = useI18next();
  const location = useLocation();

  return (
    <div className="relative bg-mine-shaft py-[45px] md:py-[85px]">
      <Clips />
      <article className="constrained sm:flex justify-start items-start">
        <div className="relative w-full max-w-[393px] ml-7 xl:ml-[111px] mr-7 sm:mr-[65px] xl:mr-[161px]">
          <div className="absolute top-[45px] right-7 xl:top-[65px] xl:right-[111px] h-full w-full bg-black" />
          <Link to={link} state={{ prevPath: location.pathname }}>
            <GatsbyImage
              image={getImage(picture) as any}
              alt={title}
              className="relative z-10"
            />
          </Link>
        </div>
        <div className="relative sm:max-w-[504px] mt-[111px] sm:mt-0">
          <footer className="flex items-center font-bold text-roboto text-12px tracking-wide uppercase">
            <div className="mr-8 text-white">{t("publications.highlight")}</div>
            <time dateTime={date} className="text-silver">
              {new Date(date).toLocaleDateString(language, {
                year: "numeric",
                month: "long",
              })}
            </time>
          </footer>
          <header>
            <h1 className="h1 mt-[35px] sm:mt-[45px] mb-[25px] font-bold tracking-wide text-white">
              {title}
            </h1>
          </header>
          <div className="whitespace-pre-line text-lg text-silver">
            {excerpt}
          </div>
          <Link
            to={link}
            state={{ prevPath: location.pathname }}
            className="btn btn-primary inline-block mt-8 sm:mt-10 px-20"
          >
            {t("publications.explore-button")}
          </Link>
        </div>
      </article>
    </div>
  );
};

export default PublicationsLastReport;
