import React, { FunctionComponent } from "react";
import PictureCard from "../PictureCard";
import Pagination from "../Pagination";
import { useLocation } from "@reach/router";
import {
  publicationsReportsList,
  publicationsReportsListItem,
} from "./index.module.css";

export type Props = {
  pages: number;
  currentPage: number;
  publications: Array<{
    link: string;
    picture: any;
    tagline: string;
    title: string;
  }>;
};

const PublicationsReports: FunctionComponent<Props> = ({
  publications,
  pages,
  currentPage,
}) => {
  const location = useLocation();

  return (
    <section className="constrained py-[145px]" id="publications-reports">
      <div className={publicationsReportsList}>
        {publications.map((pub) => (
          <PictureCard
            key={pub.link}
            href={pub.link}
            prevPath={`${location.pathname}#publications-reports`}
            className={publicationsReportsListItem}
            pictureObject={pub.picture}
            tagline={pub.tagline}
            title={pub.title}
          />
        ))}
      </div>

      <Pagination pages={pages} currentPage={currentPage} className="mt-8" />
    </section>
  );
};

export default PublicationsReports;
