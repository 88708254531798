import React from "react";
import { graphql, PageProps } from "gatsby";
import { useI18next } from "../utils/i18n";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Offices from "../components/Offices";
import PublicationsLastReport from "../components/PublicationsLastReport";
import PublicationsReports from "../components/PublicationsReports";
import NewsletterCallToAction from "../components/NewsletterCallToAction";

type DataProps = {
  lastReport: any;
  publications: any;
};

type PageContext = {
  numPages: number;
  currentPage: number;
};

const PublicationsPage: React.FC<PageProps<DataProps, PageContext>> = ({
  data,
  pageContext,
}) => {
  const { language, t } = useI18next();

  const lastReport = data.lastReport.edges[0].node;
  const publications = data.publications?.edges?.map(({ node }: any) => node);

  return (
    <Layout>
      <SEO
        title={t("publications.meta.title")}
        description={t("publications.meta.description")}
      />

      <PublicationsLastReport
        title={lastReport.frontmatter.title}
        date={lastReport.frontmatter.date}
        excerpt={lastReport.frontmatter.excerpt}
        link={lastReport.fields.slug}
        picture={
          lastReport.frontmatter.picture || lastReport.frontmatter.main_document
        }
      />

      <PublicationsReports
        pages={pageContext.numPages}
        currentPage={pageContext.currentPage}
        publications={publications.map((pub: any) => ({
          link: pub.fields.slug,
          picture: pub.frontmatter.picture || pub.frontmatter.main_document,
          tagline: new Date(pub.frontmatter.date).toLocaleDateString(language, {
            year: "numeric",
            month: "long",
          }),
          title: pub.frontmatter.title,
        }))}
      />

      <NewsletterCallToAction className="relative z-10 -mb-[250px] lg:-mb-[300px]" />
      <Offices />
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!, $skip: Int!, $limit: Int!) {
    publications: allMarkdownRemark(
      filter: {
        fields: { lang: { eq: $locale } }
        frontmatter: { type: { eq: "publication" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...listPublication
        }
      }
    }
    lastReport: allMarkdownRemark(
      filter: {
        fields: { lang: { eq: $locale } }
        frontmatter: { type: { eq: "publication" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          ...singlePublication
        }
      }
    }
  }
`;

export default PublicationsPage;
