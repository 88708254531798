import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Last: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 11 11" className={className}>
    <path
      fill="currentColor"
      d="M9 9.9c0 .6.45 1.1 1 1.1s1-.5 1-1.1V1.1c0-.6-.45-1.1-1-1.1S9 .5 9 1.1v8.8zM4.44 5.5L.3 1.7a.95.95 0 010-1.4 1.12 1.12 0 011.5 0L6.69 4.8c.41.38.41 1 0 1.38L1.8 10.71c-.41.39-1.08.39-1.5 0a.93.93 0 010-1.39L4.44 5.5z"
    />
  </svg>
);

export default Last;
