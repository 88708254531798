import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Previous: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 7 11" className={className}>
    <path
      fill="currentColor"
      d="M6.69 9.31L2.56 5.5 6.7 1.68c.41-.39.41-1 0-1.4a1.12 1.12 0 00-1.5 0L.31 4.82a.93.93 0 000 1.38l4.88 4.52c.41.39 1.08.39 1.5 0a.95.95 0 000-1.4z"
    />
  </svg>
);

export default Previous;
