import React, { FunctionComponent } from "react";
import { Link } from "../../utils/i18n";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { pictureCard, pictureCardHoverOverlay } from "./index.module.css";

export type Props = {
  pictureObject?: any;
  pictureUrl?: string;
  href: string;
  prevPath: string;
  tagline: string;
  title: string;
  className?: string;
};

const PictureCard: FunctionComponent<Props> = ({
  href,
  prevPath,
  pictureObject,
  pictureUrl,
  tagline,
  title,
  className,
}) => (
  <Link
    to={href}
    className={`${className} ${pictureCard} block`}
    state={{ prevPath }}
  >
    {!!pictureObject && (
      <GatsbyImage
        image={getImage(pictureObject) as any}
        alt={title}
        className="w-full"
      />
    )}
    {!!pictureUrl && <img src={pictureUrl} alt={title} className="w-full" />}

    <div className={pictureCardHoverOverlay} />

    <p className="text-gothic text-rubik tracking-wide mt-[25px] mb-[15px] uppercase">
      {tagline}
    </p>
    <h3 className="font-medium text-xl break-words">{title}</h3>
  </Link>
);

export default PictureCard;
